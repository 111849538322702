<template>
  <iframe style="width: 100%" :src="urlPath" title="Potree iframe"></iframe>
</template>

<script>
export default {
  data: () => ({
    urlPath: '',
    metaJson: ''
  }),
  mounted () {
    const bridgeId = this.$store.state.pointCloud.bridge_id
    const uuid = this.$store.state.user.uuid
    if (bridgeId) {
      this.urlPath =
        process.env.VUE_APP_POTREE +
        '/examples/bridge.html?bridgeId=' +
        bridgeId +
        '&uuid=' +
        uuid
    }
    console.log(this.urlPath)
  }
}
</script>

<style></style>
